import React, { useState } from 'react';
import APILink from '../http-API';


function BlogEditor() {
  const [blog, setBlog] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    image: null,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('image', formData.image);

    fetch(`${APILink}/api/blogs/`, {
      method: 'POST',
      body: formDataToSend,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.message);
        setBlog([...blog, { ...formData }]);
        setFormData({
          title: '',
          description: '',
          image: null,
        });
      })
      .catch((error) => console.error('Error posting data: ' + error));
  };

  return (
    <>
    <div className="container pt-5">
      <h2>Create Blog</h2>
      <form onSubmit={handleSubmit} className='row'>
        <div className='form-group mb-2 col-md-6'>
        <input
          type="text"
          name="title"
          className='form-control'
          placeholder="Title"
          value={formData.title}
          onChange={handleChange}
          required
        />
        </div>
        <div className='form-group mb-2 col-md-6'>
        <textarea
          name="description"
          className='form-control'
          placeholder="Description"
          value={formData.description}
          onChange={handleChange}
          required
        />
        </div>
        <div className='form-group mb-2 col-md-6'>
        <input
          type="file"
          name="image"
          className='form-control'
          onChange={handleImageChange}
          accept="image/*"
        />
        </div>
        <div className='col-md-12 text-center mb-5'>
        <button type="submit" className='btn btn-success'>Create Blog</button>
        </div>
      </form>
      </div>
    </>
  );
}

export default BlogEditor;
