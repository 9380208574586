import React, { useState } from 'react';
import APILink from '../http-API';

function BlogEditor() {
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    image: null,
  });


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('price', formData.price);
    formDataToSend.append('image', formData.image);

    fetch(`${APILink}/api/products/`, {
      method: 'POST',
      body: formDataToSend,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.message);
        setProducts([...products, { ...formData }]);
        setFormData({
          name: '',
          description: '',
          price: '',
          image: null,
        });
      })
      .catch((error) => console.error('Error posting data: ' + error));
  };

  return (
    <div className="container">
      <h2>Create Product</h2>
      <form onSubmit={handleSubmit} className='row'>
        <div className='form-group mb-2 col-md-6'>
        <input
          type="text"
          name="name"
          className='form-control'
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        </div>
        <div className='form-group mb-2 col-md-6'>
        <textarea
          name="description"
          className='form-control'
          placeholder="Description"
          value={formData.description}
          onChange={handleChange}
          required
        />
        </div>
        <div className='form-group mb-2 col-md-6'>
        <input
          type="number"
          name="price"
          className='form-control'
          placeholder="Price"
          value={formData.price}
          onChange={handleChange}
          required
        />
        </div>
        <div className='form-group mb-2 col-md-6'>
        <input
          type="file"
          name="image"
          className='form-control'
          onChange={handleImageChange}
          accept="image/*"
        />
        </div>
        <div className='col-md-12 text-center mb-5'>
        <button type="submit" className='btn btn-success'>Create Product</button>
        </div>
      </form>
    </div>
  );
}

export default BlogEditor;
