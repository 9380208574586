import React, { useState, useEffect } from 'react';
import APILink from '../../http-API';
import { Link } from 'react-router-dom';

function Collection() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    fetch(`${APILink}/api/products/`)
      .then((res) => res.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error('Error fetching data: ' + error));
  }, []);

  return (
    <div className='productListing'>
    <div className="container">
    <div className='section-title'>
      <h2>Product List</h2>
      </div>
      <div className='row'>
        {products.map((product) => (
          <div key={product.id} className='col-md-3'>
            <div className='productBox'>
            <Link to={`/product/${product.id}`}>
             {product.image && (
              <img
                src={`${APILink}/public/images/${product.image}`}
                alt={product.name}
              />
            )}
            <h4>{product.name}</h4>
            <p>{product.description}</p>
            <p>Price: {product.price}</p>
            </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default Collection;
