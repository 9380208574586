import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import APILink from '../../http-API';

const ProductDetails = () => {
  const { id } = useParams();
  const [post, setPost] = useState({});

  useEffect(() => {
    async function fetchPost() {
      try {
        const response = await axios.get(`${APILink}/api/blogs/${id}`);
        setPost(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchPost();
  }, [id]);


  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <h1>Blog Details</h1>
        </div>
        <div className='col-md-6'>
          {post.image && (
            <img
              src={`${APILink}/public/images/${post.image}`}
              alt={post.name}
            />
          )}
        </div>
        <div className='col-md-6'>
          <h4>{post.title}</h4>
          <p>{post.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
