import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
export default function NoPage() {
  useEffect(() => {
    document.body.classList.add('404-page')
    return () => {
      document.body.classList.remove('404-page')
    }
  }, []);
  return (
    <div className='page404'>
      <div className='container pt-5 pb-5 text-center'>
        <h1>404 Page</h1>
        <Link className='btn btn-success' to="/">Back To Home</Link>
      </div>
    </div>
  )
}
