import React, { useState, useEffect } from 'react';
import APILink from '../../http-API';
import { Link } from 'react-router-dom';

function Blog() {
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    fetch(`${APILink}/api/blogs/`)
      .then((res) => res.json())
      .then((data) => setBlog(data))
      .catch((error) => console.error('Error fetching data: ' + error));
  }, []);

  return (
    <>
      <div className='blogListing'>
      <div className="container">
        <div className='section-title'>
      <h2>Blog List</h2>
      </div>
      <div className='row'>
        {blog.map((post) => (
          <div key={post.id} className='col-md-3'>
            <div className='blogBox'>
            <Link to={`/blog/${post.id}`}>
             {post.image && (
              <img
                src={`${APILink}/public/images/${post.image}`}
                alt={post.title}
              />
            )}
            <h4>{post.title}</h4>
            <p>
  {post.description.length > 50 ?
    `${post.description.substring(0, 50)}...` : post.description
  }
</p>
            </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
    </>
  );
}

export default Blog;
