import React from 'react';
import { Link } from 'react-router-dom';
// import logo from './assets/logo.png';
// import { useState } from 'react';

export default function Header() {
    // const [isActive, setIsActive] = useState(false);
    // const handleClick = event => {
    //   setIsActive(current => !current);
    // };

    return (
        <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">  
             <Link to="/">
              {/* <img src={logo} alt="" className="img-fluid" /> */}
              <h1>CoreBazar</h1>
              </Link>
          </div>
          <nav id="navbar" className="navbar">
            <ul className='main-nav'>
              <li><Link className="nav-link active" to="/">Home</Link></li>
              <li><Link className="nav-link" to="/about">About Us</Link></li>
              <li><Link className="nav-link" to="/collection">Collection</Link></li>
              <li><Link className="nav-link" to="/blog">Blog</Link></li>
              <li><Link className="nav-link" to="/contact">Contact</Link></li>
              <li><Link className="getstarted" to="#about">Get Started</Link></li>
            </ul>

            <i className="bi bi-list mobile-nav-toggle" onClick={(e)=>e.target.parentNode.classList.toggle('open')}></i>
          </nav>
        </div>
      </header>
    )
}
