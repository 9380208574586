import React from 'react'
import BlogEditor from './BlogEditor';
import ProductEditor from './ProductEditor';
import FaqEditor from './FaqEditor';

export default function Dashboard() {
  return (
    <>
    <div className='Dashboard-page section-bg'>
    <BlogEditor />
    <ProductEditor />
    <FaqEditor />
    </div>
    </>
  )
}
