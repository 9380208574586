import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import APILink from '../../http-API';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});

  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await axios.get(`${APILink}/api/products/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchProduct();
  }, [id]);
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <h1>Product Details</h1>
        </div>
        <div className='col-md-6'>
          {product.image && (
            <img
              src={`${APILink}/public/images/${product.image}`}
              alt={product.name}
            />
          )}
        </div>
        <div className='col-md-6'>
          <h4>{product.name}</h4>
          <p>{product.description}</p>
          <p>Price: {product.price}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
