import React from 'react';
import Slider from "react-slick";
import Client1 from '../../../Images/client-1.png';
import Client2 from '../../../Images/client-2.png';
import Client3 from '../../../Images/client-3.png';
import Client4 from '../../../Images/client-4.png';
import Client5 from '../../../Images/client-5.png';
import Client6 from '../../../Images/client-6.png';
import Client7 from '../../../Images/client-7.png';
import Client8 from '../../../Images/client-8.png';


export default function Clients() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1
      };
        return (
            <div id="clients" className="clients">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Clients</h2>
                        <p>They trusted us</p>
                    </div>

                    <Slider {...settings}
                             classNameName='clients-slider'>
                        <div className="swiper-slide"><img src={Client1} className="img-fluid" alt="" /></div>
                        <div className="swiper-slide"><img src={Client2} className="img-fluid" alt="" /></div>
                        <div className="swiper-slide"><img src={Client3} className="img-fluid" alt="" /></div>
                        <div className="swiper-slide"><img src={Client4} className="img-fluid" alt="" /></div>
                        <div className="swiper-slide"><img src={Client5} className="img-fluid" alt="" /></div>
                        <div className="swiper-slide"><img src={Client6} className="img-fluid" alt="" /></div>
                        <div className="swiper-slide"><img src={Client7} className="img-fluid" alt="" /></div>
                        <div className="swiper-slide"><img src={Client8} className="img-fluid" alt="" /></div>
                    </Slider>
                </div>
            </div>
        )
    }
