// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import APILink from '../http-API';

function Login() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const { login } = useUser();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const userData = { username: 'user123', role: 'user' };
    login(userData);
    try {
      const response = await axios.post(`${APILink}/users`, formData);
      console.log(response.data);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsSubmitting(false); // Complete form submission
    }
  };

  return (
    <div className='container'>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input type="text" name="username" autoComplete='' onChange={handleChange} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" name="password" onChange={handleChange} />
        </div>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Login'}
        </button>
        <Link to='/signup'>Sign Up</Link>
      </form>
    </div>
  );
}

export default Login;
