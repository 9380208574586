import React from 'react';
import '../src/assets/Fonts/Font.css';
import '../src/App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Routes, Route } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import NoPage from './Pages/NoPage/NoPage';
import Collection from './Pages/Collection/Collection';
import ProductDetails from './Pages/SinglePages/ProductDetails';
import Crud from './Pages/Crud/Crud';
import Login from './Admin/Login';
import SignUp from './Admin/SignUp';
import { UserProvider } from './Admin/UserContext';
import Blog from './Pages/Blog/Blog';
import BlogDetails from './Pages/SinglePages/BlogDetails';
import Contact from './Pages/Contact/Contact';
import Dashboard from './Admin/Dashboard';

export default function App() {
  return (
    <>
     <UserProvider>
    <Header />
    <main id="main" className="main-content">
    <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/product/:id" element={ <ProductDetails /> } />
        <Route path="/crud" element={<Crud />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/crud" element={<Crud />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={ <BlogDetails /> } /> 
        <Route path="/contact" element={<Contact />} /> 
        <Route path="*" element={<NoPage />} />
       </Routes>
      </main>
      <Footer />
      </UserProvider>
    </>
    
  )
}
