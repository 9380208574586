import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APILink from '../http-API';
import { Container, Table, Button, Modal, Form } from 'react-bootstrap';

function FaqEditor() {
  const [tasks, setTasks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [taskData, setTaskData] = useState({ title: '', description: '' });
  const [editingTask, setEditingTask] = useState(null);

  useEffect(() => {
    axios.get(`${APILink}/api/faqs`).then((response) => {
      setTasks(response.data);
    });
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
    setTaskData({ title: '', description: '' });
    setEditingTask(null);
  };

  const handleAddTask = () => {
    axios.post(`${APILink}/api/faqs`, taskData).then(() => {
      setTasks([...tasks, { ...taskData }]);
      handleModalClose();
    });
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setTaskData(task);
    setShowModal(true);
  };

  const handleUpdateTask = () => {
    axios.put(`${APILink}/api/faqs/${editingTask.id}`, taskData).then(() => {
      const updatedTasks = tasks.map((task) =>
        task.id === editingTask.id ? { ...task, ...taskData } : task
      );
      setTasks(updatedTasks);
      handleModalClose();
    });
  };


  const handleDeleteTask = (taskId) => {
    axios.delete(`${APILink}/api/faqs/${taskId}`).then(() => {
      const updatedTasks = tasks.filter((task) => task.id !== taskId);
      setTasks(updatedTasks);
    });
  };

  return (
    <Container>
      <div className='section-title'>
        <h1>Task List</h1>
        <Button variant="success" onClick={() => setShowModal(true)}>  Add Faq </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (
            <tr key={task.id}>
              <td>{task.title}</td>
              <td>{task.description}</td>
              <td>
                <Button variant="info" onClick={() => handleEditTask(task)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteTask(task.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingTask ? 'Edit Task' : 'Add Task'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={taskData.title}
                onChange={(e) =>
                  setTaskData({ ...taskData, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={taskData.description}
                onChange={(e) =>
                  setTaskData({ ...taskData, description: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          {editingTask ? (
            <Button variant="primary" onClick={handleUpdateTask}>
              Save Changes
            </Button>
          ) : (
            <Button variant="primary" onClick={handleAddTask}>
              Add Task
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default FaqEditor;
