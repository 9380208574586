import React, { useState, useEffect } from 'react';
import Accordion from './Accordion';
import APILink from '../../../http-API';
export default function FaqSec() {
   const [items, setItems] = useState([]);

   useEffect(() => {
     fetch(`${APILink}/api/faqs/`)
       .then((res) => res.json())
       .then((data) => setItems(data))
       .catch((error) => console.error('Error fetching data: ' + error));
   }, []);
  return (
    <div id="faq" className="faq section-bg">
   <div className="container">
      <div className='section-title'>
         <h2>F.A.Q</h2>
   <p>Frequently Asked Questions</p>
   </div>
    <Accordion items={items} />
    </div>
    </div>
  )
}
