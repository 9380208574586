
import React, { useState } from 'react';

export default function Accordion({items}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>   <ul className='faq-list'>
        {items.map((item, index) => (
        <li className="accordion-item" key={item.title}>
              <button className={activeIndex === index ? 'accordion-button active' : 'accordion-button'} onClick={() =>handleClick(index)}>{item.title}</button>
              {index === activeIndex && <div className="accordion-body"><p>{item.description}</p></div>}
           </li>

        ))}
          </ul>
     </>
  );
}
