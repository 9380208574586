import React, { useEffect } from 'react'
import About from './component/About';
import FaqSec from './component/FaqSec';
import Clients from './component/Clients';
import Services from './component/Services';
import Team from './component/Team';
import Img from '../../Images/hero-img.svg';
import Blog from '../Blog/Blog';

export default function Home() {
  useEffect(() => {
    document.body.classList.add('index-page')
    return () => {
      document.body.classList.remove('index-page')
    }
  }, []);
  
  return (
    <>
    <div id="hero" className="d-flex align-items-center">
    <div className="container">
      <div className="row gy-4">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>Bettter digital experience with CoreBazar</h1>
          <h2>We are talented team of designers and Developers</h2>
          <div>
            <a href="#about" className="btn-get-started scrollto">Get Started</a>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img src={Img} className="img-fluid animated" alt="" />
        </div>
      </div>
    </div>
  </div>
  <About />
  <Services />
  <Team />
  <FaqSec />
  <Blog />
  <Clients />
  </>
  )
}
