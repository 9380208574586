import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APILink from '../../http-API'
import { Modal } from 'react-bootstrap';
function Crud() {
  const [tasks, setTasks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [taskData, setTaskData] = useState({ title: '', description: '', image: null });
  const [editingTask, setEditingTask] = useState(null);

  useEffect(() => {
    axios.get(`${APILink}/tasks`).then((response) => {
      setTasks(response.data);
    });
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
    setTaskData({ title: '', description: '', image: null });
    setEditingTask(null);
  };

  const handleAddTask = () => {
    axios.post(`${APILink}/tasks`, taskData).then(() => {
      setTasks([...tasks, { ...taskData }]);
      handleModalClose();
    });
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setTaskData(task);
    setShowModal(true);
  };

  const handleUpdateTask = () => {
    axios.put(`${APILink}/tasks/${editingTask.id}`, taskData).then(() => {
      const updatedTasks = tasks.map((task) =>
        task.id === editingTask.id ? { ...task, ...taskData } : task
      );
      setTasks(updatedTasks);
      handleModalClose();
    });
  };


  const handleImageChange = (e) => {
    setTaskData({
      ...taskData,
      image: e.target.files[0],
    });
  };

  const handleDeleteTask = (taskId) => {
    axios.delete(`${APILink}/tasks/${taskId}`).then(() => {
      const updatedTasks = tasks.filter((task) => task.id !== taskId);
      setTasks(updatedTasks);
    });
  };

  return (
    <div className='container'>
      <h1>Task List</h1>
      <button className='btn btn-success' onClick={() => setShowModal(true)}>
        Add Task
      </button>
      <table className='table table-striped table-bordered table-hover'>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (
            <tr key={task.id}>
              <td>{task.title}</td>
              <td>{task.description}</td>
              <td>{task.image}</td>
              <td>
                <button className='btn btn-info' onClick={() => handleEditTask(task)}>
                  Edit
                </button>
                <button className='btn btn-danger'
                  onClick={() => handleDeleteTask(task.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={showModal} onHide={handleModalClose}>
        <div className='modal-header'>
          <div className='modal-header'>
            {editingTask ? 'Edit Task' : 'Add Task'}
          </div>
        </div>
        <div className='modal-body'>
          <form>
            <div>
              <label className='form-label'>Title</label>
              <input
                id="formTitle"
                class="form-control"
                type="text"
                placeholder="Enter title"
                value={taskData.title}
                onChange={(e) =>
                  setTaskData({ ...taskData, title: e.target.value })
                }
              />
            </div>
            <div>
              <label className='form-label'>Description</label>
              <textarea
                rows="3"
                placeholder="Enter description"
                value={taskData.description}
                id="formDescription"
                class="form-control"
                onChange={(e) =>
                  setTaskData({ ...taskData, description: e.target.value })
                } />
            </div>
            <div>
              <label className='form-label'>Image</label>
              <input
                type='file'
                id='formImage'
                class="form-control"
                placeholder="Select File"
                value={taskData.image}
                onChange={handleImageChange}
                accept="image/*"
              />
            </div>
          </form>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-secondary' onClick={handleModalClose}>
            Close
          </button>
          {editingTask ? (
            <button className='btn btn-primary' onClick={handleUpdateTask}>
              Save Changes
            </button>
          ) : (
            <button className='btn btn-primary' onClick={handleAddTask}>
              Add Task
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Crud;
