import React from 'react'
import { Link } from 'react-router-dom';
import Team1 from '../../../Images/team-1.jpg';
import Team2 from '../../../Images/team-2.jpg';
import Team3 from '../../../Images/team-3.jpg';
import Team4 from '../../../Images/team-4.jpg';
export default function Team() {
    return (
        <div id="team" className="team">
            <div className="container">
                <div className="section-title" data-aos="fade-up">
                    <h2>Team</h2>
                    <p>Our team is always here to help</p>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                        <div className="member">
                            <img src={Team1} className="img-fluid" alt="" />
                            <div className="member-info">
                                <div className="member-info-content">
                                    <h4>Walter White</h4>
                                    <span>Chief Executive Officer</span>
                                </div>
                                <div className="social">
                                    <Link to=""><i className="bi bi-twitter"></i></Link>
                                    <Link to=""><i className="bi bi-facebook"></i></Link>
                                    <Link to=""><i className="bi bi-instagram"></i></Link>
                                    <Link to=""><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                        <div className="member">
                            <img src={Team2} className="img-fluid" alt="" />
                            <div className="member-info">
                                <div className="member-info-content">
                                    <h4>Sarah Jhonson</h4>
                                    <span>Product Manager</span>
                                </div>
                                <div className="social">
                                    <Link to=""><i className="bi bi-twitter"></i></Link>
                                    <Link to=""><i className="bi bi-facebook"></i></Link>
                                    <Link to=""><i className="bi bi-instagram"></i></Link>
                                    <Link to=""><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                        <div className="member">
                            <img src={Team3} className="img-fluid" alt="" />
                            <div className="member-info">
                                <div className="member-info-content">
                                    <h4>William Anderson</h4>
                                    <span>CTO</span>
                                </div>
                                <div className="social">
                                    <Link to=""><i className="bi bi-twitter"></i></Link>
                                    <Link to=""><i className="bi bi-facebook"></i></Link>
                                    <Link to=""><i className="bi bi-instagram"></i></Link>
                                    <Link to=""><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                        <div className="member">
                            <img src={Team4} className="img-fluid" alt="" />
                            <div className="member-info">
                                <div className="member-info-content">
                                    <h4>Amanda Jepson</h4>
                                    <span>Accountant</span>
                                </div>
                                <div className="social">
                                    <Link to=""><i className="bi bi-twitter"></i></Link>
                                    <Link to=""><i className="bi bi-facebook"></i></Link>
                                    <Link to=""><i className="bi bi-instagram"></i></Link>
                                    <Link to=""><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
